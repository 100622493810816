<template lang="pug">
.form.form-phone
  .form__title(v-html="formTitle")
  .form__fields
    .form__field
      .input-text(:class="{'input-text-focus': (fieldName.length >= 3) && (fieldName.length <= 40), 'input-text-error': fieldError && v$.fieldName.$invalid}")
        input.input-text__input(
          type="text"
          v-model="fieldName"
          :placeholder="formFieldNamePlaceholder"
        )

    .form__field
      .input-text(:class="{'input-text-focus': fieldPhone.length === 18, 'input-text-error': fieldError && v$.fieldPhone.$invalid}")
        input.input-text__input(
          type="tel"
          v-model="fieldPhone"
          :placeholder="formFieldPhonePlaceholder"
          v-maska="['+7 (###) ##-##-##', '+7 (###) ###-##-##']"
        )

  .form__consent
    label.consent.consent-gray
      input.consent__input(
        type="checkbox"
        value="consent"
        checked="checked"
        v-model="consent"
      )
      span.consent__button
      span.consent__label(v-html="formConsentText")

  .form__button
    .button.button-background-yellow(@click="getSend()")
      .button__text(v-html="formButtonName")

</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'
export default {
  name: 'AppFormPhone',

  setup () {
    return { v$: useVuelidate() }
  },

  props: [
    "formSettingTitle",
    "formSettingSubject",

    "formTitle",
    "formButtonName",

    "formFieldName",
    "formFieldNamePlaceholder",
    "formFieldPhone",
    "formFieldPhonePlaceholder",
    "formFieldPage",

    "formConsentText",

    "formRedirect",

    "formPageId",
    "formPagePagetitle"
  ],

  data () {
    return {
      fieldName: '',
      fieldPhone: '',
      files: '',
      consent: ['consent'],
      sendForm: false,
      fieldError: false
    }
  },

  methods: {
    ...mapActions([
      'Send'
    ]),

    getSend () {
      if (this.v$.$invalid) {
        this.fieldError = true
      }

      if (!this.v$.$invalid) {
        let curData = new Date()
        let curDay = curData.getDate()
        let curMonth = curData.getMonth() + 1
        let curYear = curData.getFullYear()

        this.sendForm = !this.sendForm

        this.Send({
          setting: {
            title: this.formSettingTitle,
            subject: this.formSettingSubject,
            formDate: curDay + '.' + curMonth + '.' + curYear
          },

          fields: [
            {
              title: this.formFieldName,
              value: this.fieldName
            },
            {
              title: this.formFieldPhone,
              value: this.fieldPhone
            },
            {
              title: this.formFieldPage,
              value: this.formPagePagetitle + ' (' + this.formPageId + ')'
            },
          ],
          files: this.files
        })

        this.fieldName = this.fieldPhone = ''

        setTimeout(() => {
          window.location.href = this.formRedirect
        }, 2500)
      }
    }
  },

  validations () {
    return {
      fieldName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(40)
      },

      fieldPhone: {
        required,
        minLength: minLength(18),
        maxLength: maxLength(18)
      },

      consent: {
        required
      }
    }
  }
}
</script>

/*global $*/
/*eslint no-undef: "error"*/

export default class ButtonShow {
    classItem = 'button-show--item'
    classButton = 'button-show--button'

    classOpen = 'text-show-open'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(this).closest(`.${self.classItem}`).addClass(self.classOpen)
        })
    }
}

/*eslint-disable no-unused-vars*/

import { createApp } from 'vue'
import Maska from 'maska'
import form from '@/store/form'
import './assets/js/css/ImportCss'
import { Fancybox } from '@fancyapps/ui'
import AppFormPhone from '@/components/Form/AppFormPhone.vue'
import AppFormOrder from '@/components/Form/AppFormOrder.vue'
import AppFormReview from '@/components/Form/AppFormReview.vue'
import SwiperSlider from '@/assets/js/Swiper/Swiper'
import ButtonMore from '@/assets/js/Button/More/ButtonMore'
import ButtonShow from '@/assets/js/Button/Show/ButtonShow'
import ButtonToggle from '@/assets/js/Button/Toggle/ButtonToggle'
import Menu from '@/assets/js/Menu/Menu'
import MenuMobile from '@/assets/js/Menu/Mobile/MenuMobile'
import MenuMobile2 from '@/assets/js/Menu/Mobile/2/MenuMobile2'
import ProductTab from '@/assets/js/Product/Tab/ProductTab'
import Map from '@/assets/js/Map/Map'
import Faq from '@/assets/js/Faq/Faq'
import Cookie from '@/assets/js/Cookie/Cookie'

// Подключаем глобально jQuery
const $ = window.$ = window.jQuery = require('jquery')

document.addEventListener('DOMContentLoaded', () => {
    Fancybox.bind('[data-fancybox]', {
        mainClass: 'fancybox--popup'
    })

    new SwiperSlider()
    new ButtonMore()
    new ButtonShow()
    new ButtonToggle()
    new Menu()
    new MenuMobile()
    new MenuMobile2()
    new ProductTab()
    new Map()
    new Faq()
    new Cookie()

    if(document.getElementById('block-form--phone') != null) {
        createApp({})
            .component('form-phone', AppFormPhone)
            .use(Maska)
            .use(form)
            .mount('#block-form--phone')
    }

    if(document.getElementById('block-form--order') != null) {
        createApp({})
            .component('form-order', AppFormOrder)
            .use(Maska)
            .use(form)
            .mount('#block-form--order')
    }

    if(document.getElementById('block-form--review') != null) {
        createApp({})
            .component('form-review', AppFormReview)
            .use(Maska)
            .use(form)
            .mount('#block-form--review')
    }
})

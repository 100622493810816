/*global $*/
/*eslint no-undef: "error"*/

export default class ButtonMore {
    classItem = 'button-more--item'
    classBlock = 'button-more--block'
    classButton = 'button-more--button'

    classBlockHidden = 'button-more-hidden'
    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let curClass= $(this).data('class')

            $(`.${self.classItem}`).addClass(curClass)
            $(this).closest(`.${self.classBlock}`).addClass(self.classBlockHidden)
        })
    }
}

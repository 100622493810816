/*global $*/
/*eslint no-undef: "error"*/

import Swiper from 'swiper'
import {Autoplay, EffectCoverflow, Navigation, Pagination, Thumbs} from 'swiper/modules'
Swiper.use([Navigation, Pagination, EffectCoverflow, Autoplay, Thumbs])

export default class SwiperSlider {

    createSlide () {
        return new Swiper('#slider--slide', {
            slidesPerView: 1,
            spaceBetween: 5,
            // loop: true,
            navigation: {
                nextEl: '.slider--slide-next',
                prevEl: '.slider--slide-prev',
            },
            pagination: {
                el: '.swiper-pagination--slide',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 5,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 5,
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 5,
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 5,
                },
                414: {
                    slidesPerView: 1,
                    spaceBetween: 5,
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 5,
                },
                514: {
                    slidesPerView: 1,
                    spaceBetween: 5
                },
                640: {
                    slidesPerView: 1,
                    spaceBetween: 5,
                },
                768: {
                    slidesPerView: 1,
                    spaceBetween: 5,
                },
                991: {
                    slidesPerView: 1,
                    spaceBetween: 5,
                },
                1024: {
                    slidesPerView: 1,
                    spaceBetween: 5,
                },
                1240: {
                    slidesPerView: 1,
                    spaceBetween: 5,
                },
                1440: {
                    slidesPerView: 1,
                    spaceBetween: 5,
                }
            }
        })
    }

    createClient () {
        return new Swiper('#slider--client', {
            slidesPerView: 20,
            spaceBetween: 6,
            // loop: true,
            navigation: {
                nextEl: '.slider--client-next',
                prevEl: '.slider--client-prev',
            },
            pagination: {
                el: '.swiper-pagination--client',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                320: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                360: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                375: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
                414: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
                480: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },
                514: {
                    slidesPerView: 3,
                    spaceBetween: 15
                },
                640: {
                    slidesPerView: 4,
                    spaceBetween: 15,
                },
                768: {
                    slidesPerView: 5,
                    spaceBetween: 15,
                },
                991: {
                    slidesPerView: 5,
                    spaceBetween: 20,
                },
                1024: {
                    slidesPerView: 5,
                    spaceBetween: 20,
                },
                1240: {
                    slidesPerView: 6,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 6,
                    spaceBetween: 20,
                }
            }
        })
    }

    createReview () {
        return new Swiper('#slider--review', {
            slidesPerView: 20,
            spaceBetween: 2,
            // loop: true,
            navigation: {
                nextEl: '.slider--review-next',
                prevEl: '.slider--review-prev',
            },
            pagination: {
                el: '.swiper-pagination--review',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                414: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                514: {
                    slidesPerView: 1,
                    spaceBetween: 15
                },
                640: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                991: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                1024: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                1240: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                }
            }
        })
    }

    constructor () {
        if (parseInt($('#slider--slide').length) > 0) {
            this.createSlide()
        }

        if (parseInt($('#slider--client').length) > 0) {
            this.createClient()
        }

        if (parseInt($('#slider--review').length) > 0) {
            this.createReview()
        }
    }
}
